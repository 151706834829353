<script>
  import DatePicker from 'vuejs-datepicker'

  export default {
    name: 'DatePicker',
    extends: DatePicker,
    props: {
      bootstrapStyling: {
        default: true,
      },
      disabled: {
        default: false,
      },
      language: {
        default: 'ru',
      },
      inputClass: {
        default: 'datepicker',
      },
      mondayFirst: {
        default: true,
      },
      format: {
        default: 'dd.MM.yyyy',
      },
      clearButton: {
        default: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ {
    input {
      background-color: #fff !important;
    }
  }
</style>
