<template>
  <b-navbar class="site-navbar navbar-default navbar-fixed-top navbar-mega navbar-inverse" role="navigation">
    <!--Mobile-Only кнопки навбара-->
    <div class="navbar-header">
      <b-btn
        variant="link"
        class="navbar-toggler hamburger hamburger-close navbar-toggler-left"
        :class="{ hided: !menuVisible }"
        @click="menuToggle"
      >
        <span class="hamburger-bar"></span>
      </b-btn>

      <b-link class="navbar-brand navbar-brand-center" to="/">
        <div
          class="navbar-brand-text hidden-xs-down"
          style="width: 140px"
          v-html="require('!!svg-inline-loader!../../../assets/img/loader-logo-white.svg')"
        ></div>
      </b-link>

      <b-btn variant="link" class="navbar-toggler" v-b-toggle.site-navbar-collapse>
        <i class="icon zmdi zmdi-more"></i>
      </b-btn>
    </div>

    <div class="navbar-container container-fluid">
      <b-collapse class="navbar-collapse navbar-collapse-toolbar" id="site-navbar-collapse">
        <!--Header-Menu (справа)-->
        <header-menu></header-menu>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
  import store from '@/store'

  import HeaderMenu from './HeaderMenu'
  import HeaderSearch from './HeaderSearch'

  export default {
    name: 'AppHeader',
    components: {
      HeaderMenu,
      HeaderSearch,
    },
    props: ['menuVisible'],
    data: function () {
      return {
        user: store.state.auth.user,
      }
    },
    methods: {
      menuToggle(e) {
        e.preventDefault()
        this.$emit('update:menuVisible', !this.menuVisible)
      },
    },
  }
</script>
