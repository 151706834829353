import forEach from 'lodash/forEach'
import cloneDeep from 'lodash/cloneDeep'

import api from '../assets/js/api'
import queryHelper from '../assets/js/query'

import rights from './rights'

export default {
  mixins: [rights],
  computed: {
    isRouteComponent() {
      let matched = this.$route.matched,
        last = matched[matched.length - 1],
        instance = last.instances && last.instances['default']

      return instance && this === instance
    },
  },
  beforeRouteEnter(to, from, next) {
    // Сущность
    let entity = to.meta.entity,
      query = queryHelper.getQuery(entity, to.fullPath, to.meta.predefined)

    get(to, from, entity, to.meta.path, query).then(result => {
      next(vm => {
        vm.$store.dispatch('entities/setEntity', entity).then(module => {
          vm.module = module

          module.dispatch('parsing', {
            to: to,
            result: result,
            predefined: to.meta.predefined,
          })

          callback(to, from, result, vm, true)
        })
      })
    })
  },
  beforeRouteUpdate(to, from, next) {
    if (!to.params.id || to.params.id !== from.params.id) {
      // Сущность
      let entity = to.meta.entity
      get(to, from, entity, to.meta.path).then(result => {
        this.module.dispatch('parsing', {
          to: to,
          result: result,
          update: true,
        })
        callback(to, from, result, this)
        next()
      })
    } else {
      next()
    }
  },
  created() {
    // Чистка объектов в соответствии с правами
    if (this.table) {
      this.table.fields && this.cleanRolesElements(this.table.fields)
    }
    this.filters && this.cleanRolesElements(this.filters)

    // Запрос данных для компонентов, которые несвязанны с роутами
    if (!this.isRouteComponent && this.getData) {
      let entity = this.getData.entity,
        query = queryHelper.getQuery(entity, this.$route.fullPath, this.getData.predefined)

      get(this.$route, this.$route, entity, this.getData.path, query, true).then(result => {
        this.$store.dispatch('entities/setEntity', entity).then(module => {
          this.module = module

          module.dispatch('parsing', {
            to: this.$route,
            result: result,
            predefined: this.getData.predefined,
          })

          callback(this.$route, this.$route, result, this, true)
        })
      })
    }
  },
  watch: {
    $route(route) {
      if (!this.isRouteComponent && this.getData) {
        let entity = this.getData.entity
        get(route, route, entity, this.getData.path, null, true).then(result => {
          this.module.dispatch('parsing', {
            to: route,
            result: result,
            update: true,
          })
          callback(route, route, result, this)
        })
      }
    },
  },
}

function get(to, from, entity, path, query, withoutId) {
  return api.base.get({
    id: !withoutId && to.params.id,
    entity: entity,
    path: path,
    params: query,
    storeSync: true,
    to: to,
    from: from,
  })
}

function callback(to, from, result, vm, enter) {
  vm.module.commit('setOriginalResult', cloneDeep(result))

  if (typeof vm.resultHandler === 'function') {
    vm.resultHandler(result)
  }

  vm.module.commit('setResult', result)

  let items = result.items || result.data
  items = items ? items : Array.isArray(result) ? result : [result]

  forEach(items, item => {
    if (typeof item.visible !== 'undefined') {
      item._rowVariant = !item.visible ? 'not-visible' : null
    }
  })

  items && vm.module.commit('setItems', items)

  if (typeof vm.readyCallback === 'function') {
    vm.readyCallback(enter)
  }
}
