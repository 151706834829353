import axios from 'axios'
import qs from 'qs'
import { notification } from 'ant-design-vue'

const paramsSerializer = params => qs.stringify(params, { arrayFormat: 'brackets' })

export const api = axios.create({
  baseURL: process.env.API_URL_NEW,
  paramsSerializer,
  params: {
    token: null,
  },
  withCredentials: false,
})

api.interceptors.response.use(
  response => response,
  e => {
    errorNotification(e)
    return Promise.reject(e)
  }
)

export const legacyApi = axios.create({
  baseURL: process.env.API_URL,
  paramsSerializer,
  params: {
    token: null,
  },
  withCredentials: true,
})

function errorNotification(e) {
  const {
    response: { data, status },
  } = e
  const message = `Error - ${status}`

  notification.error({
    placement: 'bottomRight',
    message,
    description: data.message || null,
  })
}
