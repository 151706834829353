import Vue from 'vue'
import Router from 'vue-router'

// Store
import store from '@/store'

Vue.use(Router)

import routes from './routes'
import qs from 'qs'

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  stringifyQuery(query) {
    const result = qs.stringify(query)
    return result ? `?${result}` : ''
  },
  parseQuery(query) {
    return qs.parse(query)
  },
  scrollBehavior(to, from, savedPosition) {
    return new Promise(resolve => {
      if (savedPosition) {
        resolve(savedPosition)
      } else {
        resolve({
          x: 0,
          y: 0,
        })
      }
    })
  },
  routes: routes,
})

router.beforeEach(async (to, from, next) => {
  store.commit('currentView/setRouteUpdate', false)

  if (from.path === to.path && to.path === to.fullPath) {
    let module = await store.dispatch('entities/findModule', { by: 'path', value: to.path })
    module.commit('reset', ['filters', 'sort', 'pagination'])
  }

  next()
})

router.beforeResolve(async (to, from, next) => {
  if (from.path !== to.path) {
    store.commit('currentView/resetData')
    await store.dispatch('entities/resetEntities')
  }

  next()
})

export default router
