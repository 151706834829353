import axios from 'axios'
import axiosCancel from 'axios-cancel'
import qs from 'qs'
import store from '@/store'
import router from '@/router'
import _set from 'lodash/set'
import { erpTokenPath } from '@/consts'

import notification from './notification'

// Новый ЧИСТЫЙ абстрагированный инстанс
let httpClean = axios.create({
  baseURL: process.env.API_URL,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' }),
  withCredentials: true,
})

// Новый абстрагированный инстанс
let http = axios.create({
  baseURL: process.env.API_URL,
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'brackets' })
  },
  withCredentials: true,
})

axios.defaults.withCredentials = true
httpClean.defaults.withCredentials = true
http.defaults.withCredentials = true

setAxiosToken()

axiosCancel(http, {
  debug: false, // default
})

http.interceptors.request.use(
  function (config) {
    // Добавляем таски в loader перед загрузкой
    store.dispatch('loader', {
      start: true,
    })

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  success => {
    store.dispatch('loader', {
      start: false,
    })

    return Promise.resolve(success)
  },
  error => {
    store.dispatch('loader', {
      start: false,
    })

    if (error.response && error.response.status) {
      if (error.response.status === 401) {
        store.commit('setAuthorized', false)
        router.push('/login')
      } else if (!error.request.responseURL.match('auth/login')) {
        notification['error'](
          (error.response.data && error.response.data.message) || 'Неизвестная причина',
          'Ошибка [' + error.response.status + ']'
        )
      }
    }

    return Promise.reject(error)
  }
)

function saveToken(token = null, setToken = true) {
  if (token) localStorage.setItem(erpTokenPath, token)
  else localStorage.removeItem(erpTokenPath)
  if (setToken) setAxiosToken(token)
}

function setAxiosToken(token) {
  token = token || localStorage.getItem(erpTokenPath)
  _set(http.defaults, 'params.token', token)
  _set(httpClean.defaults, 'params.token', token)
}

export const api = {
  httpClean: httpClean,
  http: http,
  auth: {
    login(data) {
      return new Promise((resolve, reject) => {
        http
          .post('/auth/login', data)
          .then(result => {
            if (result.status === 200) {
              const { token } = result.data
              saveToken(token)
              resolve(result.data)
            } else {
              saveToken()
              reject('Неизвестная ошибка, попробуйте ещё раз!')
            }
          })
          .catch(e => {
            saveToken()
            reject('Неизвестная ошибка, попробуйте ещё раз!')
          })
      })
    },
    logout() {
      return new Promise((resolve, reject) => {
        http.post('/auth/logout').then(
          () => {
            localStorage.removeItem('erpToken')
            resolve()
          },
          error => {
            reject(error)
          }
        )
      })
    },
  },
  base: {
    get(config) {
      this.storeSync(config)

      let path = config.path || config.entity
      let requestId = config.requestId || path + Date.now()

      if (Array.isArray(config.ids)) {
        let promises = config.ids.map((id, index) => {
          return baseGet(id, `${requestId}_${index}`)
        })

        return Promise.all(promises)
      } else {
        return baseGet(config.id, requestId)
      }

      function baseGet(id, requestId) {
        return new Promise((resolve, reject) => {
          http
            .get(`/${path}${id ? `/${id}` : ''}`, {
              params: config.params || {},
              headers: config.headers || {},
              requestId,
            })
            .then(
              result => {
                resolve(result.data)
              },
              error => {
                if (!axios.isCancel(error)) {
                  reject(error)
                }
              }
            )
        })
      }
    },
    update(config) {
      return new Promise((resolve, reject) => {
        this.storeSync(config)

        http[config.method || (config.id ? 'put' : 'post')](
          '/' + (config.path || config.entity) + (config.id ? '/' + config.id : ''),
          config.data,
          {
            params: config.params || {},
          }
        ).then(
          result => {
            resolve(result.data)
            if (config.entity) {
              notification['success'](
                api.entities[config.entity].names[0] + ' ' + (config.id ? 'обновлён' : 'создан'),
                'Успешно'
              )
            }
          },
          error => {
            reject(error)
          }
        )
      })
    },
    storeSync(config) {
      if (
        config.storeSync &&
        config.to &&
        config.from &&
        config.to.path === config.from.path &&
        store.getters['entities/' + config.entity + '/query']
      ) {
        config.params = Object.assign({}, config.params, store.getters['entities/' + config.entity + '/query'])
      }
    },
  },
  entities: {
    departments: {
      names: ['Отдел', 'Отделы'],
    },
    employees: {
      names: ['Сотрудник', 'Сотрудники'],
    },
    projects: {
      names: ['Проект', 'Проекты'],
    },
    tasks: {
      names: ['Задача', 'Задачи'],
    },
    users: {
      names: ['Пользователь', 'Пользователи'],
    },
  },
}

export default api
