import NProgress from 'nprogress'

NProgress.configure({
  showSpinner: false,
  speed: 300,
  easing: 'ease-in-out',
})

// initial state
const state = {
  tasks: 0,
  doneTimeout: null,
}

// actions
const actions = {
  loader({ commit, dispatch }, data) {
    commit(data.start ? 'addTask' : 'removeTask')
    if (NProgress) {
      dispatch('checkTasks', data)
    }
  },
  checkTasks({ commit, state }, data) {
    let { start } = data
    let { tasks, doneTimeout } = state

    if (start && !NProgress.status) {
      NProgress.start()
    }

    if (NProgress.status) {
      NProgress.inc()
    }

    if (!tasks) {
      if (doneTimeout) {
        clearTimeout(doneTimeout)
      }
      commit(
        'setDoneTimeout',
        setTimeout(() => {
          NProgress.done()
        }, 500)
      )
    }
  },
}

// mutations
const mutations = {
  addTask(state) {
    state.tasks += 1
  },
  removeTask(state) {
    let tasks = state.tasks - 1

    if (tasks >= 0) state.tasks = tasks
    else state.tasks = 0
  },
  setDoneTimeout(state, timeout) {
    state.doneTimeout = timeout
  },
}

export default {
  actions,
  state,
  mutations,
}
