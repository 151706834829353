<script>
  import Modal from '../../../components/Modal'

  export default {
    name: 'edit-document',
    extends: Modal,
    data() {
      return {
        entity: 'documents',
        path: 'accounting/documents',
        fields: [
          {
            name: 'type_id',
            label: 'Тип документа',
            type: 'select',
            items: [
              { name: 'Акт', value: 1 },
              { name: 'Счет', value: 2 },
            ],
          },
        ],
      }
    },
  }
</script>
