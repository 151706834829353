export default [
  {
    path: 'reports/projects_movement',
    name: 'Отчёт по Проектам',
    component: () => import('../views/Reports/ProjectsMovement/Report'),
    meta: {
      entity: 'reports/projects_movement',
    },
  },
  {
    path: 'reports/tasks_movement',
    name: 'Отчёт по Задачам',
    component: () => import('../views/Reports/TasksMovement/Report'),
    meta: {
      entity: 'reports/tasks_movement',
    },
    roles: ['admin'],
  },
  {
    path: 'reports/employees_activity',
    name: 'Отчёт по Сотрудникам',
    component: () => import('../views/Reports/EmployeesActivity/Report'),
    meta: {
      entity: 'reports/employees_activity',
    },
  },
  {
    path: 'reports/clients',
    name: 'Клиенты / Проекты',
    component: () => import('../views/Reports/Clients/Report'),
    meta: {
      entity: 'reports/clients',
      path: 'reports/clients',
    },
  },
  {
    path: 'reports/clients_monthly',
    name: 'Клиенты (по месяцам)',
    component: () => import('../views/Reports/ClientsNew/Report'),
    meta: {
      entity: 'reports/clients_monthly',
      path: 'reports/clients_monthly',
    },
    roles: ['admin'],
  },
  {
    path: 'reports/projects_monthly',
    name: 'Проекты (по месяцам)',
    component: () => import('../views/Reports/Projects/Report'),
    meta: {
      entity: 'reports/projects_monthly',
      path: 'reports/projects_monthly',
    },
    roles: ['admin'],
  },
  {
    path: 'reports/salary',
    name: 'Зарплата и Премии',
    component: () => import('../views/Reports/Salary/Report'),
    meta: {
      entity: 'reports/salary',
      path: 'reports/salary',
      predefined: {
        with: ['project.last_touch'],
      },
    },
  },
  {
    path: 'reports/salary_monthly',
    name: 'Зарплата (по месяцам)',
    component: () => import('../views/Reports/SalaryMonthly/Report'),
    meta: {
      entity: 'reports/salary_monthly',
      path: 'reports/salary_monthly',
    },
    roles: ['admin'],
  },
  {
    path: 'reports/intervals',
    name: 'Интервалы',
    component: () => import('../views/Reports/Intervals/Report'),
    meta: {
      entity: 'intervals',
      path: 'intervals/daily',
      predefined: {
        with: [
          'worth',
          'cost',
          'rate',
          'userRate',
          'remainder',
          'margin',
          'task',
          'user',
          'user.employee',
          'project',
          'time_detail',
          'project.employee_statistic_total',
        ],
      },
    },
    roles: ['admin'],
  },
  {
    path: 'reports/pnl_monthly',
    name: 'Прибыль / Убытки',
    component: () => import('../views/Reports/PNL/Report'),
    meta: {
      entity: 'reports/pnl_monthly',
    },
    roles: ['admin'],
  },
  {
    path: 'reports/by-months',
    name: 'Отчёт по месяцам',
    component: () => import('../modules/report-by-months/components/View'),
    roles: ['admin'],
  },
  {
    path: 'reports/acts',
    name: 'Отчёт по актам',
    component: () => import('../modules/acts/components/View'),
  },
]
