<template>
  <list v-if="entity" :entity="entity" :filters="filters">
    <div slot="list" slot-scope="data">
      <b-card>
        <b-table
          :items="items"
          :fields="table.fields"
          :sort-by="data.sort.by"
          :sort-desc="data.sort.desc"
          @sort-changed="$emit('sortChange', $event)"
          no-local-sorting
          responsive
        >
          <template slot="number" slot-scope="data">
            <router-link :to="`/accounting/documents/${data.item.id}`">
              {{ data.item.name }}
            </router-link>
          </template>

          <template slot="created_at" slot-scope="data">
            {{ data.value | formatDate('DD/MM/YYYY') }}
          </template>

          <template slot="type_id" slot-scope="data">
            {{ data.item.type.name }}
          </template>

          <span slot="status_id" slot-scope="data" :style="{ color: $dotGet(data.item, 'status.color') }">
            {{ $dotGet(data.item, 'status.name', '-') }}
          </span>

          <template slot="sum_items" slot-scope="data"> {{ data.item.total | formatNumber }} &#8381; </template>

          <template slot="personTo.fields.name" slot-scope="data">
            <p class="wrapped" v-b-tooltip:hover :title="data.value" v-html="data.value"></p>
          </template>

          <template slot="personTo.fields.full_name" slot-scope="data">
            <p class="wrapped" v-b-tooltip:hover :title="data.value" v-html="data.value"></p>
          </template>

          <template slot="actions" slot-scope="data">
            <a
              target="_blank"
              :href="`https://erp.rocketsales.ru/api/v1/accounting/documents/print/${data.item.hash}?from=erp`"
            >
              <i class="zmdi zmdi-print"></i>
            </a>
            <a
              href="asd"
              v-b-tooltip.hover
              :title="`Синхронизировать реквизиты`"
              @click.prevent.stop="syncRequisites(data.item)"
              style="margin-left: 25px"
            >
              <i class="zmdi zmdi-refresh"></i>
            </a>
          </template>
        </b-table>
        <edit-document :source="modal.item" :module="module"></edit-document>
      </b-card>
    </div>
  </list>
</template>

<script>
  import api from '../../../assets/js/api'

  import List from '../../../components/List'
  import EditDocument from './EditDocument'

  import entityMixins from '../../../mixins/entity'

  export default {
    name: 'DocumentsList',
    mixins: [entityMixins],
    components: {
      List,
      EditDocument,
    },
    mounted() {
      const vm = this

      this.$store.commit('currentView/setActions', [
        {
          label: 'Новый документ',
          icon: 'plus-square',
          onClick() {
            vm.modalSwitch(null)
          },
        },
      ])
    },
    data() {
      return {
        table: {
          fields: [
            {
              key: 'id',
              label: 'ID',
              class: 'text-center',
              sortable: true,
            },
            {
              key: 'number',
              label: 'Название',
              sortable: true,
            },
            {
              key: 'type_id',
              label: 'Тип',
              sortable: true,
            },
            {
              key: 'personTo.fields.name',
              label: 'Клиент',
            },
            {
              key: 'personTo.fields.full_name',
              label: 'Юр. лицо',
            },
            {
              key: 'sum_items',
              label: 'Сумма',
            },
            {
              key: 'created_at',
              label: 'Дата документа',
              class: 'text-center',
              sortable: true,
            },
            {
              key: 'status_id',
              label: 'Статус',
              class: 'text-center',
              sortable: true,
            },
            {
              key: 'actions',
              label: 'Действия',
              class: 'text-center',
            },
          ],
        },
        filters: [
          {
            name: 'name',
            width: 3,
            label: 'Поиск по клиенту',
          },
          {
            name: 'price',
            width: 2,
            label: 'Поиск по сумме',
          },
          {
            name: 'create_date',
            type: 'date',
            legend: 'Дата',
            width: 2,
          },
          {
            label: 'Статус документа',
            name: 'status',
            type: 'select',
            width: 2,
            items: [
              {
                name: 'Все документы',
                value: '',
              },
              {
                name: 'Не оплачены/подписаны',
                value: 'not_paid',
              },
              {
                name: 'Оплачены/подписаны',
                value: 'paid',
              },
            ],
          },
          {
            label: 'Тип документа',
            name: 'type',
            type: 'select',
            width: 2,
            items: [
              {
                name: 'Все документы',
                value: '',
              },
              {
                name: 'Счета',
                value: 2,
              },
              {
                name: 'Акты',
                value: 3,
              },
            ],
          },
        ],
      }
    },
    methods: {
      async syncRequisites(item) {
        const url = `https://erp.rocketsales.ru/api/v1/accounting/documents/sync-requisites/${item.id}`

        api.http.post('/accounting/documents/sync-requisites', { id: item.id }).then(
          result => {
            if (result.data.status === 'ok') {
              alert(result.data.text)
            } else {
              alert(result.data.text)
            }
          },
          error => {
            alert('Серверная ошибка')
          }
        )
      },
      createAct(e, item) {
        e.preventDefault()
        e.stopPropagation()

        if (confirm('Уверены что хотите создать акт к счету #' + item.number + ' на сумму ' + item.total + '₽?')) {
          api.http.post('/accounting/documents/create-act', { billId: item.id }).then(
            result => {
              if (result.data.status === 'ok') {
                alert(result.data.text)
              } else {
                alert(result.data.text)
              }
            },
            error => {
              alert('Серверная ошибка')
            }
          )
        }
      },
      refillBalance(e, item) {
        e.preventDefault()
        e.stopPropagation()

        if (confirm('Уверены что хотите пополнить баланс по акту #' + item.number + ' на сумму ' + item.total + '₽?')) {
          api.http.post('/accounting/documents/refill-balance', { actId: item.id }).then(
            result => {
              if (result.data.status === 'ok') {
                alert(result.data.text)
              } else {
                alert(result.data.text)
              }
            },
            error => {
              alert('Серверная ошибка')
            }
          )
        }
      },
    },
  }
</script>

<style scoped>
  .wrapped {
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
  }
</style>
