export default {
  data() {
    return {
      modalItem: null,
      modal: {
        item: null,
        id: null,
        component: null,
      },
    }
  },
  methods: {
    modalSwitch(item, id, component) {
      id = id || 'edit-' + (this.entity && this.entity.name)
      component = component || id

      this.modal.item = item
      this.modal.id = id
      this.modal.component = component

      this.$nextTick(() => {
        this.showModal()
      })
    },
    showModal() {
      this.$root.$emit('bv::show::modal', this.modal.id)
    },
  },
}
