import { api } from '../../api'

// initial state
const state = {
  acts: [],
  saleManagers: [],
  responsibleUsers: [],
  projectManagers: [],
  status: [],
  type: [],
  count: 0,
  loading: true,
  error: '',
}

const actions = {
  async getActs({ commit }, params) {
    try {
      commit('setLoading', true)
      const {
        data: { acts, count, responsible_users, status, type: typeofLead, project_managers, sale_managers },
      } = await api.get('/acts', { params: { filter: params } })
      commit('setCount', count)
      commit('setActs', acts)
      commit('setResponsibleUsers', responsible_users)
      commit('setSaleManagers', sale_managers)
      commit('setTypeOfLead', typeofLead)
      commit('setStatusOfLead', status)
      commit('setProjectManagers', project_managers)
    } catch (e) {
      commit('setError', e.message)
    } finally {
      commit('setLoading', false)
    }
  },
}

const mutations = {
  setResponsibleUsers(state, payload) {
    state.responsibleUsers = payload
  },
  setProjectManagers(state, payload) {
    state.projectManagers = payload
  },
  setActs(state, payload) {
    state.acts = payload
  },
  setTypeOfLead(state, payload) {
    state.type = payload
  },
  setStatusOfLead(state, payload) {
    state.status = payload
  },
  setSaleManagers(state, payload) {
    state.saleManagers = payload
  },
  setCount(state, payload) {
    state.count = payload
  },
  setLoading(state, payload) {
    state.loading = payload
  },
  setError(state, payload) {
    state.error = payload
  },
}

const getters = {
  getActs: state => {
    return (state.acts || []).map((e, i) => ({ ...e, num: i }))
  },
  getProjectManagers: state => {
    return state.projectManagers
  },
  getResponsibleUsers: state => {
    return state.responsibleUsers
  },
  getSaleManagers: state => {
    return state.saleManagers
  },
  getLoadingActs: state => {
    return state.loading
  },
  getCountActs: state => {
    return state.count
  },
  getType: state => {
    return state.type
  },
  getStatus: state => {
    return state.status
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
