<template>
  <ol class="breadcrumb">
    <li class="breadcrumb-item" v-for="(item, index) in list" v-if="item.name">
      <span class="active" v-if="isLast(index)">{{ item.name }}</span>
      <router-link :to="item" v-else>{{ item.name }}</router-link>
    </li>
  </ol>
</template>

<script>
  export default {
    methods: {
      isLast(index) {
        return index === this.list.length - 1
      },
    },
    computed: {
      list() {
        return this.$route.matched
      },
      // Deprecated
      //   routeMatched () {
      //       let pathSplit = this.$route.path.split('/'), matched = []
      //       pathSplit.reduce((accumulator, value, index, array) => {
      //         let path = accumulator + '/' + value,
      //           match = this.$router.matcher.match(path)
      //         if (match && match.name) {
      //           matched.push(Object.assign({}, match));
      //         }
      //         return path
      //       });
      //       return matched
      //     }
    },
  }
</script>
