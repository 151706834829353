import api from '../assets/js/api'

import modalMixins from './modal'
import viewDataMixins from './view-data'

export default {
  mixins: [modalMixins, viewDataMixins],
  data() {
    return {
      module: null,
    }
  },
  computed: {
    basePath() {
      return (
        (this.getData && (this.getData.path || this.getData.entity)) ||
        this.$route.meta.path ||
        this.$route.meta.entity ||
        null
      )
    },
    entity() {
      return this.module && this.module.state
    },
    items() {
      return (this.entity && this.entity.items) || []
    },
    item() {
      return this.items[0] || null
    },
    currentEntity() {
      return (this.getData && this.getData.entity) || this.$route.meta.entity || null
    },
    isRouteComponent() {
      let matched = this.$route.matched,
        last = matched[matched.length - 1],
        instance = last.instances && last.instances['default']

      return instance && this === instance
    },
  },
  methods: {
    changeVisible(item, visible) {
      api.base
        .update({
          entity: this.entity.name,
          id: item.id,
          data: {
            visible: visible ? 1 : 0,
          },
        })
        .then(() => {
          this.module.commit('updateItem', {
            source: item,
            merge: true,
            data: {
              visible: visible ? 1 : 0,
              _rowVariant: visible ? null : 'not-visible',
            },
          })
        })
    },
  },
  created() {
    // HMR FIX
    if (!this.module && this.currentEntity && this.isRouteComponent) {
      let module = this.$store._modulesNamespaceMap['entities/' + this.currentEntity + '/']
      if (module) {
        this.module = module.context
      }
    }
  },
}
