import Vue from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import mergeWith from 'lodash/mergeWith'
import forEach from 'lodash/forEach'
import set from 'lodash/set'

import query from '../../assets/js/query'

const initialState = {
  name: null,
  common: null,
  items: [],
  filters: {},
  sort: {
    by: null,
    desc: false,
  },
  pagination: null,
  with: [],
  extraQuery: null,
  predefined: null,
  path: null,
  result: null,
  originalResult: null,
}

// initial state
const state = () => {
  return cloneDeep(initialState)
}

const actions = {
  parseQuery({ commit, state, getters }, data) {
    commit('setPath', data.to.path)
    commit('setPredefined', data.predefined)

    let parsed = Object.assign(
      {},
      query.parsePredefined(data.predefined),
      query.parseQuery(state.name, data.to.fullPath),
      query.parseResult(state.name, data.result)
    )

    // if (parsed.filters) {
    //   commit('setFilters', parsed.filters)
    // }

    if (parsed.sort) {
      commit('setSort', parsed.sort)
    }

    if (parsed.pagination) {
      commit('setPagination', Object.assign({}, state.pagination, parsed.pagination))
    }

    if (parsed.with) {
      commit('setWith', parsed.with)
    }

    if (parsed.extraQuery) {
      commit('setExtraQuery', parsed.extraQuery)
    }

    return getters.query
  },

  parsing({ commit, state, getters }, data) {
    if (!data.update) {
      commit('setPath', data.to.path)
      commit('setPredefined', data.predefined)
    }

    let parsed = !data.update
      ? merge(
          {},
          query.parsePredefined(data.predefined),
          query.parseQuery(state.name, data.to.fullPath),
          query.parseResult(state.name, data.result)
        )
      : query.parseResult(state.name, data.result)

    if (parsed.pagination) {
      commit('setPagination', Object.assign({}, state.pagination, parsed.pagination))
    }

    if (!data.update) {
      if (parsed.filters) {
        commit('setFilters', parsed.filters)
      }

      if (parsed.sort) {
        commit('setSort', parsed.sort)
      }

      if (parsed.with) {
        commit('setWith', parsed.with)
      }

      if (parsed.extraQuery) {
        commit('setExtraQuery', parsed.extraQuery)
      }
    }

    return getters.query
  },
}

const getters = {
  structuredQuery(state) {
    return query.structuredQuery(state)
  },

  query(state, getters) {
    return query.query(state.name, getters.structuredQuery)
  },
}

// mutations
const mutations = {
  setName(state, name) {
    state.name = name
  },

  setPagination(state, pagination) {
    state.pagination = pagination
  },

  updatePagination(state, data) {
    state.pagination = Object.assign({}, state.pagination, data)
  },

  setSort(state, sort) {
    state.sort = sort
  },

  updateSort(state, data) {
    state.sort = Object.assign({}, state.sort, data)
  },

  setItems(state, items) {
    state.items = Array.isArray(items) ? items : [items]
  },

  updateItem(state, data) {
    let index

    if (data.index || data.index === 0) {
      index = data.index
    } else if (data.id) {
      index = state.items.findIndex(item => {
        return item.id === data.id
      })
    }

    if (data.merge) {
      forEach(data.data, (value, key) => {
        if (index) {
          Vue.set(state.items[index], key, value)
        } else if (data.source) {
          Vue.set(data.source, key, value)
        }
      })
    } else {
      // index может быть 0
      if (typeof index !== 'undefined') {
        Vue.set(state.items, index, data.data)
      }
    }

    // Пробую новую схему (та, что выше)
    // if (data.prop) {
    //   Vue.set(state.items[index], data.prop, data.data)
    // } else if (data.merge) {
    //   if (data.soft) {
    //     Object.assign(state.items[index], data.data)
    //   } else {
    //     Vue.set(state.items, index, Object.assign(state.items[index], data.data))
    //   }
    // } else {
    //   Vue.set(state.items, index, data.data)
    // }
  },

  insertItems(state, items) {
    !Array.isArray(items) && (items = [items])

    items.forEach(item => {
      state.items.unshift(item)
    })
  },

  setCommon(state, common) {
    state.common = common
  },

  setFilters(state, filters) {
    state.filters = filters
  },

  setFilterValue(state, data) {
    let newData = set({}, data.name, data.data),
      oldData = cloneDeep(state.filters)

    let result = mergeWith({}, oldData, newData, (objValue, srcValue) => {
      if (Array.isArray(objValue)) {
        return srcValue
      }
    })

    state.filters = result
  },

  updateFilter(state, data) {
    let index = state.filters.findIndex(filter => {
      return filter.name === data.name
    })

    Vue.set(state.filters, index, Object.assign({}, state.filters[index], data.data))
  },

  setWith(state, withs) {
    state.with = withs
  },

  setExtraQuery(state, extraQuery) {
    state.extraQuery = extraQuery
  },

  setPredefined(state, predefined) {
    state.predefined = predefined
  },

  setPath(state, path) {
    state.path = path
  },

  setResult(state, result) {
    state.result = result
  },

  setOriginalResult(state, result) {
    state.originalResult = result
  },

  reset(state, keys) {
    if (keys) {
      !Array.isArray(keys) && (keys = [keys])

      forEach(keys, key => {
        if (state[key]) {
          Vue.set(state, key, cloneDeep((state.predefined && state.predefined[key]) || initialState[key]))
        }
      })
    } else {
      forEach(state, (value, key) => {
        Vue.set(state, key, cloneDeep((state.predefined && state.predefined[key]) || initialState[key]))
      })
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
