<template>
  <div class="view-actions">
    <b-btn
      v-if="actions && actions.length"
      v-for="(action, index) in actions"
      :key="index"
      :variant="action.variant || 'primary'"
      :to="action.to || null"
      :disabled="action.disabled || null"
      @click="onClick($event, action)"
    >
      <fa v-if="action.icon" :icon="action.icon"></fa>
      {{ action.label }}
    </b-btn>
  </div>
</template>

<script>
  export default {
    name: 'actions',
    computed: {
      actions() {
        return this.$store.state.currentView.actions
      },
    },
    methods: {
      onClick(e, action) {
        e.preventDefault()
        e.stopPropagation()

        if (action.onClick) {
          action.onClick()
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .view-actions {
    text-align: right;

    .btn {
      margin-left: 10px;
      svg {
        margin-right: 3px;
      }
    }
  }
</style>
