import remove from 'lodash/remove'
import intersection from 'lodash/intersection'

export default {
  computed: {
    userRoles() {
      return this.$store.state.auth.user.positions
    },
  },
  methods: {
    checkRoles(roles) {
      return !!intersection(this.userRoles, roles).length
    },
    cleanRolesElements(source) {
      remove(source, item => {
        return item.roles && item.roles.length && !this.checkRoles(item.roles)
      })
    },
  },
}
