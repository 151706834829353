<template>
  <b-modal
    :id="id"
    :title="isNew ? newTitle : title"
    :ok-title="isNew ? okNewTitle : okTitle"
    :ok-only="okOnly"
    @ok="send"
    @show="onShow"
  >
    <b-form @submit="send">
      <b-form-group v-for="field in fields" :key="field.name" :label="field.label">
        <select-dropdown
          v-if="field.type === 'select'"
          :items="field.items"
          :ajax="field.ajax"
          :multiple="field.multiple || false"
          v-model="field.value"
        ></select-dropdown>
        <b-form-checkbox v-else-if="field.type === 'checkbox'" v-model="field.value" :value="1" :unchecked-value="0">
        </b-form-checkbox>
        <date-picker v-else-if="field.type === 'date'" v-model="field.value"> </date-picker>
        <b-form-input v-else-if="true" :name="field.name" :placeholder="field.placeholder" v-model="field.value">
        </b-form-input>
      </b-form-group>
      <slot></slot>
      <input type="submit" style="position: absolute; visibility: hidden" />
    </b-form>
  </b-modal>
</template>

<script>
  import api from '../assets/js/api'
  import queryHelper from '../assets/js/query'
  import forEach from 'lodash/forEach'
  import get from 'lodash/get'
  import set from 'lodash/set'

  import DatePicker from './DatePicker'
  import SelectDropdown from './SelectDropdown'

  export default {
    name: 'EditModal',
    components: {
      DatePicker,
      SelectDropdown,
    },
    props: {
      module: Object,
      source: Object,
      single: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        entity: null,
        path: null,
        title: 'Редактирование',
        newTitle: 'Создать',
        okTitle: 'Сохранить',
        okNewTitle: 'Создать',
        okOnly: true,
        fields: [],
        predefined: null,
      }
    },
    computed: {
      id() {
        return 'edit-' + this.entity
      },
      isNew() {
        return this.source === null
      },
      formData() {
        let data = {}
        forEach(this.fields, field => {
          set(data, field.name, field.value)
        })
        return data
      },
    },
    methods: {
      onShow() {
        if (this.source) {
          this.getEntity().then(result => {
            this.fieldsSet(get(result, 'item', result))
            this.onShowCallback && this.onShowCallback()
          })
        } else {
          this.fieldsSet(this.source)
          this.onShowCallback && this.onShowCallback()
        }
      },
      async getEntity() {
        try {
          return await api.base.get({
            id: this.source['id'],
            entity: this.entity,
            path: this.path,
            params: queryHelper.getQuery(this.entity, this.$route.path, this.predefined),
          })
        } catch (e) {}
      },
      fieldsSet(source) {
        forEach(this.fields, field => {
          let value

          if (typeof field.customValue === 'function') {
            value = field.customValue(this.source)
          } else {
            value = get(source, keyReplace(field.customProp || field.name))
          }

          this.$set(field, 'value', value)

          function keyReplace(name) {
            return name.replace(/oneExtra/g, 'extra')
          }
        })
      },
      send(e) {
        e.preventDefault()

        api.base
          .update({
            entity: this.entity,
            path: this.path,
            params: queryHelper.getQuery(this.entity, this.$route.path, this.predefined),
            id: this.isNew ? null : this.source['id'],
            data: this.formData,
            storeSync: this.isNew,
            to: this.$route,
            from: this.$route,
          })
          .then(result => this.sendCallback(result))
      },
      sendCallback(result) {
        this.$root.$emit('bv::hide::modal', this.id)
        if (!this.isNew) {
          ;(this.module || this.$store).commit((!this.module ? 'entities/' + this.entity + '/' : '') + 'updateItem', {
            merge: true,
            source: this.source,
            data: Object.assign({}, this.source, result),
          })

          if (this.single) {
            this.$store.commit('currentView/setMeta', {
              title: result.name,
            })
          }
        } else {
          ;(this.module || this.$store).commit(
            (!this.module ? 'entities/' + this.entity + '/' : '') + 'insertItems',
            result
          )
        }
      },
    },
  }
</script>

<style lang="scss">
  /deep/ {
    legend {
      font-size: inherit;
    }
  }
</style>
