<template>
  <div class="site-menubar site-menubar-light">
    <div class="site-menubar-body">
      <b-nav class="site-menu">
        <template v-for="item in menu">
          <b-nav-item v-if="!item.items" class="site-menu-item has-sub" :to="item.link">
            <i class="site-menu-icon zmdi" :class="'zmdi-' + item.icon" aria-hidden="true"></i>
            <span class="site-menu-title">{{ item.label }}</span>
          </b-nav-item>

          <b-nav-item-dropdown class="site-menu-item has-sub" v-else no-caret>
            <template slot="button-content">
              <i class="site-menu-icon zmdi" :class="'zmdi-' + item.icon" aria-hidden="true"></i>
              <span class="site-menu-title">{{ item.label }}</span>
              <span class="site-menu-arrow"></span>
            </template>

            <div class="site-menu-scroll-wrap is-list">
              <ul class="site-menu-sub site-menu-normal-list">
                <li class="site-menu-item" v-for="subItem in item.items">
                  <b-link class="animsition-link" :to="subItem.link">
                    <span class="site-menu-title">{{ subItem.label }}</span>
                  </b-link>
                </li>
              </ul>
            </div>
          </b-nav-item-dropdown>
        </template>
      </b-nav>
    </div>
  </div>
</template>

<script>
  import cloneDeep from 'lodash/cloneDeep'

  import reportRoutes from '../../router/reports'
  import rightsMixins from '../../mixins/rights'

  export default {
    name: 'AppMenu',
    mixins: [rightsMixins],
    data() {
      return {
        items: [
          {
            label: 'Рабочий стол',
            icon: 'apps',
            link: '/dashboard',
          },
          {
            label: 'Клиенты',
            icon: 'accounts-list-alt',
            link: '/clients',
            roles: ['admin'],
          },
          {
            label: 'Проекты',
            icon: 'case',
            link: '/projects',
          },
          {
            label: 'Задачи',
            icon: 'format-list-bulleted',
            link: '/tasks',
            roles: ['manager'],
          },
          {
            label: 'Бухгалтерия',
            icon: 'cake',
            roles: ['admin', 'cashier'],
            items: [
              {
                label: 'Проводки',
                link: '/accounting/operations',
              },
              {
                label: 'Документы',
                link: '/accounting/documents',
              },
            ],
          },
          {
            label: 'Сотрудники',
            icon: 'accounts',
            link: '/employees',
            roles: ['admin'],
          },
          {
            label: 'Отчёты',
            icon: 'trending-up',
            roles: ['user'],
            items: reportRoutes.map(({ name, path, roles }) => {
              return {
                label: name,
                link: '/' + path,
                roles,
              }
            }),
          },
        ],
      }
    },
    computed: {
      menu() {
        let menu = []
        let items = cloneDeep(this.items)

        items.forEach(item => {
          /**
           * Если у пункта есть роли и мы им не соответствуем -
           * не добавляем
           */
          if (item.roles && !this.checkRoles(item.roles)) {
            return
          }

          /**
           * Если нет подпунктов - добавляем сразу
           */
          if (!item.items) {
            return menu.push(item)
          }

          /**
           * Фильтруем подпункты, оставляя только те,
           * которые соответствуют роли
           */
          item.items = item.items.filter(subItem => {
            return !subItem.roles || this.checkRoles(subItem.roles)
          })

          /**
           * Если подпунктов нет -
           * не добавляем
           */
          if (!item.items.length) {
            return
          }

          menu.push(item)
        })

        return menu
      },
    },
  }
</script>

<style lang="scss" scoped>
  .site-navbar-small {
    .site-menubar {
      /deep/ {
        $margin-left: 160px;
        $margin-right: 80px;

        @media (min-width: 767px) {
          margin-left: $margin-left;
          margin-right: 80px;
          padding-top: 5px;
          width: calc(100% - #{$margin-left} - #{$margin-right});
          position: fixed;
          top: 0;
          z-index: 1500;
          background: none;
          box-shadow: none;
          color: #fff;
          height: 4.286rem;

          a.nav-link {
            & > * {
              color: #fff !important;
            }
          }

          .dropdown {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            .dropdown-menu {
              top: 6px !important;
            }
          }
        }

        .site-menu.nav {
          display: inherit;
        }
      }
    }
  }
</style>
