<template>
  <b-row style="padding: 0 15px">
    <span class="datepicker-arrow-left" @click.prevent.stop="changePeriod(false)"><fa icon="caret-left"></fa></span>
    <b-col v-if="!filter.range">
      <filter-date-picker
        ref="date"
        :name="filter.name"
        :placeholder="filter.label"
        @selected="$emit('change', { $event, filter })"
        :minimumView="filter.monthOnly ? 'month' : 'day'"
        :format="filter.monthOnly ? 'MMMM yyyy' : undefined"
        :value="value || ''"
      />
      <span v-show="value" class="reset"><fa icon="times" size="sm" @click="reset(null)" /></span>
    </b-col>
    <template v-else>
      <b-col class="pr-0">
        <filter-date-picker
          ref="from"
          :name="filter.name"
          :placeholder="(filter.label && filter.label.from) || 'c'"
          @selected="$emit('change', { $event, filter, prop: 'from' })"
          :minimumView="filter.monthOnly ? 'month' : 'day'"
          :format="filter.monthOnly ? 'MMMM yyyy' : undefined"
          :value="(value && value.from) || ''"
        >
        </filter-date-picker>
        <span v-show="value && value.from" class="reset double-left" @click="reset('from')"
          ><fa icon="times" size="sm"
        /></span>
      </b-col>
      <b-col class="pl-2">
        <filter-date-picker
          :name="filter.name"
          :placeholder="(filter.label && filter.label.to) || 'по'"
          @selected="$emit('change', { $event, filter, prop: 'to' })"
          :minimumView="filter.monthOnly ? 'month' : 'day'"
          :format="filter.monthOnly ? 'MMMM yyyy' : undefined"
          :value="(value && value.to) || ''"
        >
        </filter-date-picker>
        <span v-show="value && value.to" class="reset double-right"
          ><fa icon="times" size="sm" @click="reset('to')"
        /></span>
      </b-col>
    </template>
    <span class="datepicker-arrow-right" @click.prevent.stop="changePeriod(true)"><fa icon="caret-right"></fa></span>
  </b-row>
</template>

<script>
  import get from 'lodash/get'
  import DatePicker from 'vuejs-datepicker'

  // Стандартные настройки DatePicker'а
  const FilterDatePicker = {
    extends: DatePicker,
    props: {
      bootstrapStyling: {
        default: true,
      },
      language: {
        default: 'ru',
      },
      inputClass: {
        default: 'datepicker',
      },
      mondayFirst: {
        default: true,
      },
      format: {
        default: 'dd.MM.yyyy',
      },
    },
  }

  export default {
    name: 'date-picker',
    components: {
      FilterDatePicker,
    },
    props: ['filter', 'value'],
    methods: {
      changePeriod(next) {
        if (this.filter.range) {
          let fromDate = moment(get(this.$refs, ['from', 'selectedDate'], null))

          if (fromDate._isValid) {
            let month = fromDate[next ? 'add' : 'subtract'](1, 'month')

            this.$emit('change', {
              $event: {
                from: month.startOf('month').format('YYYY-MM-DD'),
                to: month.endOf('month').format('YYYY-MM-DD'),
              },
              filter: this.filter,
            })
          }
        } else {
          let date = moment(get(this.$refs, ['date', 'selectedDate'], null))

          if (date._isValid) {
            this.$emit('change', {
              $event: date[next ? 'add' : 'subtract'](1, 'day'),
              filter: this.filter,
            })
          }
        }
      },
      reset(key) {
        let value = this.value

        if (key) {
          value = Object.assign({}, this.value)
          value[key] = ''
        } else {
          value = ''
        }

        this.$emit('change', {
          $event: value,
          filter: this.filter,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .reset {
    cursor: pointer;

    position: absolute;
    top: 7px;
    z-index: 9;

    right: 23px;

    &.double-left {
      right: 8px;
    }

    &.double-right {
      right: 23px;
    }

    &:not(:hover) {
      svg {
        opacity: 0.5;
      }
    }
  }

  /deep/ {
    input.datepicker {
      padding-right: 20px;
    }
  }
</style>
