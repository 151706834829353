<template>
  <b-nav class="navbar-toolbar navbar-right navbar-toolbar-right">
    <b-nav-item-dropdown no-caret extra-toggle-classes="navbar-avatar">
      <template slot="button-content">
        <span class="avatar">
          <img :src="user.avatar" />
        </span>
      </template>

      <template v-for="item in avatarMenu.items">
        <b-dropdown-divider v-if="item.divider"></b-dropdown-divider>
        <b-dropdown-item v-else :to="item.link" @click="avatarMenu.onClick($event, item)" :target="item.target">
          <i class="icon zmdi" :class="'zmdi-' + item.icon"></i>{{ item.label }}
        </b-dropdown-item>
      </template>
    </b-nav-item-dropdown>
  </b-nav>
</template>

<script>
  import store from '../../../store'

  export default {
    name: 'header-menu',
    data() {
      return {
        user: store.state.auth.user,
        avatarMenu: {
          items: [
            // {
            //   label: 'Профиль',
            //   icon: 'account'
            // },
            {
              label: 'TD авторизация',
              icon: 'time',
              link: '/api/integrations/timedoctor/auth',
              target: '_blank',
            },
            // {
            //   label: 'Биллинг',
            //   icon: 'card'
            // },
            {
              divider: true,
            },
            {
              label: 'Выйти',
              icon: 'account',
              onClick() {
                store.dispatch('signOut')
              },
            },
          ],
          onClick(e, item) {
            if (typeof item.onClick === 'function') {
              e.preventDefault()
              item.onClick()
            }
          },
        },
      }
    },
  }
</script>

<style scoped></style>
