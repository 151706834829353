import Vue from 'vue'

// initial state
const state = {
  meta: {
    title: null,
  },
  actions: [],
  routeUpdate: false,
}

// actions
const actions = {}

// mutations
const mutations = {
  // Сброс
  resetData(state) {
    state.meta = {}
    state.actions = []
  },

  // Meta
  setMeta(state, meta) {
    state.meta = Object.assign({}, state.meta, meta)
  },

  // Кнопки действий
  setActions(state, actions) {
    if (Array.isArray(actions)) {
      state.actions = actions
    } else {
      state.actions = [actions]
    }
  },

  updateAction(state, data) {
    if (state.actions[data.index]) {
      Vue.set(state.actions, data.index, Object.assign(state.actions[data.index], data.action))
    }
  },

  setRouteUpdate(state, need) {
    state.routeUpdate = need
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
