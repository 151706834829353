import Vue from 'vue'
import Vuex from 'vuex'
import _throttle from 'lodash/throttle'

import auth from './modules/auth'
import loading from './modules/loading'
import currentView from './modules/current-view'
import entities from './modules/entities'
import acts from './modules/acts'

Vue.use(Vuex)

const debug = false
const store = new Vuex.Store({
  strict: debug,
  modules: {
    auth,
    currentView,
    loading,
    entities,
    acts,
  },
  state: {
    windowHeight: getHeight(),
    debug: debug,
  },
  mutations: {
    setWindowHeight(state) {
      state.windowHeight = getHeight()
    },
  },
  getters: {
    workHeight: state => {
      return state.windowHeight - 300
    },
  },
})

function getHeight() {
  return window.innerHeight
}

window.addEventListener(
  'resize',
  _throttle(() => {
    store.commit('setWindowHeight')
  }, 200)
)

export default store
