import toastr from 'toastr'

toastr.options = {
  closeButton: true,
  progressBar: true,
  positionClass: 'toast-bottom-right',
  showDuration: 300,
  hideDuration: 300,
  timeOut: 3000,
  extendedTimeOut: 1000,
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
}

export const notifications = toastr
export default notifications
