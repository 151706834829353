import store from '../index'

import entity from './entity'

// initial state
const state = () => {
  return {}
}

const getters = {
  totalQuery(state, getters) {
    let modules = Object.keys(state),
      query = { filters: {}, sort: {}, page: {} }

    modules.forEach(module => {
      let structured = getters[module + '/structuredQuery']

      if (Object.keys(structured.filters).length) {
        Object.assign(query.filters, {
          [module]: structured.filters,
        })
      }

      if (structured.sort) {
        Object.assign(query.sort, {
          [module]: structured.sort,
        })
      }

      if (structured.page && structured.page > 1) {
        Object.assign(query.page, {
          [module]: structured.page,
        })
      }
    })

    return query
  },
}

// actions
const actions = {
  findModule({ state }, data) {
    return new Promise(resolve => {
      Object.keys(state).forEach(key => {
        let entity = state[key]
        if (entity[data.by] === data.value) {
          resolve(store._modulesNamespaceMap['entities/' + entity.name + '/'].context)
        }
      })
    })
  },

  setEntity({ commit, state }, name) {
    return new Promise(resolve => {
      store.registerModule(['entities', name], entity)
      commit(name + '/setName', name)
      resolve(store._modulesNamespaceMap['entities/' + name + '/'].context)
    })
  },

  resetEntity({ commit, dispatch, state }, name) {
    if (state[name]) {
      //commit(name + '/reset')
      commit('removeEntity', name)
    }
  },

  resetEntities({ commit, dispatch, state }) {
    return new Promise(resolve => {
      Object.keys(state).forEach(module => {
        dispatch('resetEntity', module)
      })
      resolve()
    })
  },
}

// mutations
const mutations = {
  removeEntity(state, name) {
    store.unregisterModule(['entities', name])
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
