import router from '@/router'
import api from '../../assets/js/api'
import { api as newApi, legacyApi } from '../../api'

// initial state
const state = {
  authorized: false,
  user: false,
}

// actions
const actions = {
  signOut({ commit }) {
    api.auth.logout().then(() => {
      commit('setAuthorized', false)
      router.push({
        path: '/login',
        query: {
          logout: true,
        },
      })
    })
  },
}

// mutations
const mutations = {
  setAuthorized(state, authorized) {
    state.authorized = authorized
  },
  setUser(state, user) {
    state.user = user

    if (user) {
      const { token } = user
      newApi.defaults.params.token = token
      legacyApi.defaults.params.token = token
    }
  },
}

export default {
  state,
  actions,
  mutations,
}
