<template>
  <b-collapse class="navbar-search-overlap" id="site-navbar-search">
    <form role="search">
      <div class="form-group">
        <div class="input-search">
          <i class="input-search-icon zmdi zmdi-search"></i>
          <input type="text" class="form-control" placeholder="Поиск" />
          <b-btn class="input-search-close icon zmdi zmdi-close" v-b-toggle.site-navbar-search></b-btn>
        </div>
      </div>
    </form>
  </b-collapse>
</template>

<script>
  export default {
    name: 'header-search',
  }
</script>

<style scoped></style>
