<template>
  <div class="view" v-if="entity">
    <slot name="top"></slot>
    <search-filters
      :entity="entity"
      v-if="filters && filters.length"
      ref="searchFilters"
      :filters="filters"
    ></search-filters>
    <slot name="list" v-if="entity.sort" :sort="currentSort"></slot>
    <b-pagination
      v-if="pagination"
      class="pagination-no-border"
      size="sm"
      v-show="showPagination"
      :limit="8"
      :total-rows="pagination.totalCount"
      :per-page="pagination.perPage || pagination.totalCount"
      :last-text="lastPageText"
      v-model="currentPage"
      @change="paginationChange"
    ></b-pagination>
  </div>
</template>

<script>
  import SearchFilters from './Filters/Filters'

  export default {
    name: 'list',
    components: {
      SearchFilters,
    },
    props: {
      entity: Object,
      filters: Array,
    },
    computed: {
      pagination() {
        return this.entity.pagination
      },
      showPagination() {
        if (this.pagination) {
          return this.pagination.totalCount > (this.pagination.perPage || this.pagination.totalCount)
        } else return false
      },
      lastPageText() {
        let text = ''

        if (this.pagination) {
          let lastPage = Math.ceil(this.pagination.totalCount / (this.pagination.perPage || this.pagination.totalCount))

          if (lastPage - this.currentPage > 5) {
            text = lastPage.toString()
          }
        }

        return text
      },
      currentSort() {
        return this.entity && this.entity.sort
      },
      currentPage: {
        get() {
          return this.entity && this.entity.pagination.currentPage
        },
        set(value) {
          this.$store.commit('entities/' + this.entity.name + '/updatePagination', {
            currentPage: value,
          })
        },
      },
    },
    methods: {
      paginationChange(page) {
        this.$nextTick(() => {
          this.$store.commit('currentView/setRouteUpdate', true)
        })
      },
    },
    created() {
      this.$parent.$on('sortChange', sort => {
        this.$store.commit('entities/' + this.entity.name + '/setSort', {
          by: sort.sortBy,
          desc: sort.sortDesc,
        })
        this.$store.commit('currentView/setRouteUpdate', true)
      })
    },
  }
</script>
