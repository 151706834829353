<template>
  <div id="app" :class="classList">
    <app-header :menuVisible.sync="menu.visible"></app-header>
    <app-menu></app-menu>
    <div class="page">
      <div class="page-header mx-auto" v-if="!dashboard">
        <b-row>
          <b-col>
            <h1 class="page-title">{{ name }}</h1>
            <app-breadcrumb></app-breadcrumb>
          </b-col>
          <b-col>
            <app-actions></app-actions>
          </b-col>
        </b-row>
      </div>

      <div class="page-content container-fluid">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
  import AppHeader from '../components/Base/Header/Header'
  import AppMenu from '../components/Base/Menu'
  import AppBreadcrumb from '../components/Base/Breadcrumb'
  import AppActions from '../components/Base/Actions'

  export default {
    name: 'Full',
    components: {
      AppHeader,
      AppBreadcrumb,
      AppMenu,
      AppActions,
    },
    data() {
      return {
        menu: {
          visible: false,
        },
      }
    },
    computed: {
      dashboard() {
        return this.$route.path === '/dashboard'
      },
      classList() {
        return {
          'animation-fade': false,
          'site-menubar-open': this.menu.visible,
          'site-menubar-hide': !this.menu.visible,
          dashboard: this.dashboard,
        }
      },
      name() {
        if (this.$store.state.currentView.meta && this.$store.state.currentView.meta.title) {
          return this.$store.state.currentView.meta.title
        } else {
          return this.$route.name
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  #app {
    &:not(.dashboard) {
      .page-content.container-fluid {
        width: 90% !important;
      }
      .page-header {
        width: 90% !important;
      }
    }
  }
</style>
