<template>
  <b-card class="filters m-0" header-class="card-header-transparent" no-body>
    <b-card-body>
      <b-row>
        <b-col
          v-for="filter in filters"
          :key="filter.name"
          :id="filter.id"
          :class="{ hidden: filter.hidden }"
          :md="filter.width || 3"
        >
          <b-form-group :class="{ 'legend-hidden': !filter.legend }" horizontal :label="filter.legend">
            <template v-if="filter.type === 'checkbox'">
              <b-form-checkbox
                class="checkbox-custom checkbox-primary"
                :name="filter.name"
                :checked="$dotGet(entity['filters'], filter.name)"
                value="Y"
                unchecked-value="N"
                @change="updateFilters($event, filter)"
                >{{ filter.label }}
              </b-form-checkbox>
            </template>
            <template v-else-if="filter.type === 'date'">
              <filter-date-picker
                :filter="filter"
                :value="$dotGet(entity['filters'], filter.name)"
                @change="updateFilters($event.$event, $event.filter, $event.prop)"
              ></filter-date-picker>
            </template>
            <template v-else-if="filter.type === 'select'">
              <select-dropdown
                :ajax="filter.ajax"
                :items="filter.items"
                :multiple="filter.multiple"
                :name="filter.name"
                :value="$dotGet(entity['filters'], filter.name)"
                :placeholder="filter.label"
                @change="updateFilters($event, filter)"
              ></select-dropdown>
            </template>
            <template v-else>
              <b-form-input
                :name="filter.name"
                :type="filter.type || 'text'"
                :value="$dotGet(entity['filters'], filter.name)"
                :placeholder="filter.label"
                @change.native="updateFilters($event.target.value, filter)"
              ></b-form-input>
            </template>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
  import FilterDatePicker from './DatePicker'
  import SelectDropdown from '../SelectDropdown'

  export default {
    name: 'search-filters',
    components: {
      FilterDatePicker,
      SelectDropdown,
    },
    props: {
      entity: Object,
      header: {
        type: String,
        default: 'Фильтры',
      },
      filters: Array,
    },
    methods: {
      updateFilters(value, filter, prop) {
        this.$emit('updateFilters')

        if (filter.type === 'date') {
          if (filter.range) {
            value.from && (value.from = moment(value.from).format('YYYY-MM-DD'))
            value.to && (value.to = moment(value.to).format('YYYY-MM-DD'))
          } else if (value) value = moment(value).format('YYYY-MM-DD')
        }

        this.$store.commit('entities/' + this.entity.name + '/setFilterValue', {
          name: filter.name + (prop ? '.' + prop : ''),
          data: value,
        })

        this.$store.commit('currentView/setRouteUpdate', true)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .card {
    border-bottom: 1px solid #e0e0e0;

    /deep/ {
      .card-body {
        padding-bottom: calc(1.429rem - 15px);

        .legend-hidden {
          .form-row {
            > div {
              &:first-child {
                display: none;
              }
              &[role='group'] {
                flex: 0 0 100%;
                max-width: 100%;
              }
            }
          }
        }

        .row {
          > [class^='col'] {
            margin-bottom: 15px;
          }
        }

        fieldset {
          margin: 0;
        }

        legend {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }

        .form-control.datepicker {
          background-color: #fff;
        }

        .datepicker-arrow-left {
          cursor: pointer;
          z-index: 9;
          position: absolute;
          top: 8px;
          left: 5px;
        }

        .datepicker-arrow-right {
          cursor: pointer;
          z-index: 9;
          position: absolute;
          top: 8px;
          right: 5px;
        }

        .checkbox-custom {
          margin: 8px 0 0;
        }
      }
    }
  }
</style>
