import MoneyOperations from '../views/Money/Operations'
import MoneyDocuments from '../views/Money/Documents/List'

export default [
  {
    path: 'accounting/operations',
    name: 'Проводки',
    component: MoneyOperations,
    meta: {
      entity: 'operations',
      path: 'accounting/operations',
      predefined: {
        with: ['category', 'bankAccount', 'contractor', 'project'],
      },
    },
  },
  {
    path: 'accounting/documents',
    name: 'Документы',
    component: MoneyDocuments,
    meta: {
      entity: 'documents',
      path: 'accounting/documents',
      predefined: {
        with: ['status', 'category', 'type', 'personTo', 'personTo.fields'],
      },
    },
  },
  {
    path: 'accounting/documents/:id',
    name: 'Документ',
    component: () => import('../views/Money/Documents/View'),
    meta: {
      entity: 'documents',
      path: 'accounting/documents',
    },
  },
]
