<template>
  <list v-if="entity" :entity="entity" :filters="filters">
    <div slot="list" slot-scope="data">
      <b-card>
        <b-table
          :class="{ fixed: true }"
          :fields="table.fields"
          :items="items"
          :sort-by="data.sort.by"
          :sort-desc="data.sort.desc"
          @sort-changed="$emit('sortChange', $event)"
          no-local-sorting
          responsive
        >
          <template slot="date" slot-scope="data">
            {{ data.item.created_at | formatDate }}
          </template>

          <template slot="income" slot-scope="data" v-if="data.item.amount > 0">
            {{ Math.abs(data.item.amount) | formatNumber }}
          </template>

          <template slot="outcome" slot-scope="data" v-if="data.item.amount < 0">
            {{ Math.abs(data.item.amount) | formatNumber }}
          </template>

          <template slot="category" slot-scope="data">
            {{ (data.item.category && data.item.category.name) || '-' }}
          </template>

          <template slot="project" slot-scope="data">
            <template v-if="data.item.project">
              <template v-if="getLink(data.item.project, 'ERP') !== ''">
                <a target="_blank" :href="getLink(data.item.project, 'ERP')">[E]</a>
              </template>

              <template v-if="getLink(data.item.project, 'amoCRM') !== ''">
                <a target="_blank" :href="getLink(data.item.project, 'amoCRM')">[A]</a>
              </template>

              <template v-if="getLink(data.item.project, 'reportClients') !== ''">
                <a target="_blank" :href="getLink(data.item.project, 'reportClients')">[RC]</a>
              </template>

              <span>{{ data.item.project.name }}</span>
            </template>
            <template v-else>-</template>
          </template>

          <template slot="contractor" slot-scope="data">
            {{ (data.item.contractor && data.item.contractor.name) || '-' }}
          </template>

          <template slot="comment" slot-scope="data">
            {{ data.item.comment || '-' }}
          </template>

          <template slot="bankAccount" slot-scope="data">
            {{ (data.item.bankAccount && data.item.bankAccount.name) || '-' }}
          </template>
        </b-table>
      </b-card>
    </div>
  </list>
</template>

<script>
  import entityMixins from '../../mixins/entity'

  export default {
    name: 'DocumentsOperations',
    components: {
      list: () => import('../../components/List'),
    },
    methods: {
      getLink(project, type) {
        let link = ''
        if (!project.links.length) {
          return link
        }

        Object.keys(project.links).forEach(key => {
          if (project.links[key].name === type) {
            link = project.links[key].link
          }
        })

        return link
      },
    },
    mixins: [entityMixins],
    data() {
      return {
        table: {
          fields: [
            {
              key: 'date',
              label: 'Дата',
              class: 'id text-center',
            },
            {
              key: 'bankAccount',
              label: 'Счет',
              class: 'text-center account',
            },
            {
              key: 'income',
              label: 'Приход',
              class: 'text-center income',
            },
            {
              key: 'outcome',
              label: 'Расход',
              class: 'text-center outcome',
            },
            {
              key: 'category',
              label: 'Статья',
              class: 'text-left category',
            },
            {
              key: 'project',
              label: 'Проект',
              class: 'text-left project',
            },
            {
              key: 'contractor',
              label: 'Контрагент',
              class: 'text-left contractor',
            },
            {
              key: 'comment',
              label: 'Комментарий',
              class: 'text-left comment',
            },
          ],
        },
        filters: [
          {
            label: 'Быстрый поиск...',
            name: 'name',
          },
        ],
      }
    },
    created() {
      this.$store.commit('currentView/setActions', [
        {
          label: '+ Проводка',
          to: '/accounting/operations/add',
        },
      ])
    },
  }
</script>

<style lang="scss" scoped>
  /deep/ {
    .table-responsive.fixed {
      table {
        table-layout: fixed;
        width: auto;
      }
    }

    td {
      &.id {
        min-width: 80px;
      }

      &.account {
        min-width: 150px;
        max-width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &.income,
      &.outcome,
      &.level {
        min-width: 100px;
        max-width: 100px;
        font-weight: bold;
      }

      &.income {
        background: rgba(0, 128, 0, 0.05);
        color: rgba(0, 128, 0, 1);
      }

      &.outcome {
        background: rgba(255, 0, 0, 0.05);
        color: rgba(255, 0, 0, 1);
      }

      &.category {
        min-width: 200px;
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &.project {
        min-width: 200px;
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &.contractor {
        min-width: 200px;
        max-width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
</style>
