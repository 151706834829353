<template>
  <b-dropdown
    ref="dropdown"
    :text="dinamycPlaceholder"
    variant="light"
    :value="modelValue"
    :disabled="disabled || isDisabled"
    @shown="onShow"
    @hidden="onHidden"
    no-flip
  >
    <b-dropdown-header class="dropdown-search m-0" @click.prevent.stop>
      <b-form-input placeholder="Поиск" v-model="search"></b-form-input>
    </b-dropdown-header>

    <template v-for="item in dropdownItems">
      <template v-if="item.items">
        <b-dropdown-header class="dropdown-optgroup" v-if="!item.hidden">{{ item.name }}</b-dropdown-header>

        <b-dropdown-item
          v-for="subItem in item.items"
          :key="subItem.value"
          v-if="!subItem.hidden"
          :disabled="disabled || isDisabled"
          @click.prevent.stop="choice(subItem)"
          :title="subItem.description"
          activeClass="checked"
          :active="subItem.checked"
          >{{ subItem.name }}<span v-if="subItem.checked" class="float-right">&#x2713;</span>
        </b-dropdown-item>
      </template>

      <b-dropdown-item
        v-else-if="!item.hidden"
        :disabled="disabled || isDisabled"
        @click.prevent.stop="choice(item)"
        :title="item.description"
        activeClass="checked"
        :active="item.checked"
        >{{ item.name }}<span v-if="item.checked" class="float-right">&#x2713;</span>
      </b-dropdown-item>
    </template>
  </b-dropdown>
</template>

<script>
  import api from '../assets/js/api'
  import { cloneDeep, debounce, uniqWith, get } from 'lodash'

  export default {
    name: 'select-dropdown',
    model: {
      event: 'change',
    },
    props: {
      ajaxAsync: {
        type: [Function, Boolean],
        default: false,
      },
      ajax: {
        type: Object,
        default() {
          return null
        },
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: 'Выберите вариант',
      },
      items: {
        type: Array,
        default() {
          return []
        },
      },
      value: null,
    },
    data() {
      return {
        open: false,
        loading: false,
        disabled: false,
        dropdownItems: cloneDeep(this.items),
        modelValue: this.multiple ? (Array.isArray(this.value) ? this.value : [this.value]) : this.value,
        search: '',
      }
    },
    computed: {
      optgroups() {
        return this.dropdownItems.filter(item => {
          return item.items
        })
      },
      options() {
        let options = []

        this.dropdownItems.forEach(item => {
          if (!item.items) {
            options.push(item)
          } else {
            item.items.forEach(item => {
              options.push(item)
            })
          }
        })

        return options
      },
      checked() {
        return this.options.filter(item => {
          return item.checked
        })
      },
      internalValue() {
        if (this.checked.length) {
          if (!this.multiple) {
            return this.checked[0].value
          } else {
            return this.checked.map(item => {
              return item.value
            })
          }
        } else return null
      },
      dinamycPlaceholder() {
        if (this.loading) {
          return 'Загрузка...'
        } else if (this.checked.length) {
          if (!this.multiple) {
            return this.checked[0].name
          } else {
            return this.checked.length + ' вариант' + end(this.checked.length)

            function end(length) {
              let last = length % 10

              if (last === 1) {
                return ''
              } else if ([2, 3, 4].indexOf(last) + 1) {
                return 'а'
              } else return 'ов'
            }
          }
        } else return this.placeholder
      },
    },
    methods: {
      getLabel(item) {
        return (this.ajax && this.ajax.customLabel && this.ajax.customLabel(item)) || item.name
      },
      getId(item) {
        return (this.ajax && this.ajax.customId && this.ajax.customId(item)) || item.id || item.value
      },
      choice(item) {
        let state = !!item.checked

        if (!this.multiple) {
          this.reset()
        } else {
          this.$refs.dropdown.visible = true
        }

        this.$set(item, 'checked', !state)

        this.$emit('change', this.internalValue)
      },
      async autoComplete(search) {
        if (this.ajaxAsync) {
          this.mergeItems(await this.ajaxAsync(search))
        } else if (!this.ajax) {
          search = search.toLowerCase().trim()

          let iterator = item => {
            let name = item.name.toLowerCase().trim(),
              result = !name.match(search)
            this.$set(item, 'hidden', result)
            return result
          }

          this.dropdownItems.forEach(item => {
            if (item.items) {
              let length = item.items.length

              item.items.forEach(item => {
                if (iterator(item)) {
                  length -= 1
                }
              })

              this.$set(item, 'hidden', !length)
            } else {
              iterator(item)
            }
          })
        } else {
          if (search) {
            this.ajaxSearch({
              params: {
                filters: {
                  [this.ajax.entity]: {
                    name: search,
                  },
                },
              },
            })
          } else {
            this.mergeItems([])
          }
        }
      },
      mergeItems(items) {
        items = cloneDeep(items)

        items = uniqWith(
          [
            ...items.map(item => {
              let name = this.getLabel(item)
              let value = this.getId(item)

              return {
                name,
                value,
              }
            }),
            ...cloneDeep(this.dropdownItems.filter(i => i.checked)),
          ],
          (a, b) => {
            return this.getLabel(a) === this.getLabel(b)
          }
        )

        if (this.modelValue) {
          items.forEach(item => {
            if (this.multiple) {
              let value = this.modelValue.map(v => v.toString())
              let itemId = this.getId(item).toString()

              item.checked = value.indexOf(itemId) !== -1
            } else {
              item.checked = this.getId(item).toString() === this.modelValue.toString()
            }
          })
        }

        this.dropdownItems = items
      },
      ajaxSearch: debounce(function (config) {
        config = Object.assign(
          {},
          {
            requestId: 'ajaxSearch_' + (this.ajax.entity || this.ajax.path),
            entity: this.ajax.entity,
            path: this.ajax.path,
            ignoreStore: true,
          },
          config || {}
        )

        api.base
          .get(config)
          .then(result => {
            let items = result.items ? result.items : result

            if (!Array.isArray(items)) {
              items = [items]
            }

            this.mergeItems(items)
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      }, 300),
      onShow() {
        this.open = true
        this.search = ''
        setTimeout(() => {
          this.$el.querySelector('.dropdown-search input').focus()
        }, 0)
      },
      onHidden() {
        this.open = false
      },
      set(value) {
        if (!this.notEmpty(value)) {
          value = this.value
        }

        this.modelValue = value
        this.reset()

        if (this.ajax && !this.open && this.notEmpty(value)) {
          this.loading = true

          this.ajaxSearch({
            [this.multiple ? 'ids' : 'id']: value,
          })
        } else {
          if (this.notEmpty(value)) {
            !Array.isArray(value) && (value = [value])

            value.forEach(value => {
              let find = this.options.find(option => {
                return option.value.toString() === value.toString()
              })

              find && this.$set(find, 'checked', true)
            })
          }
        }
      },
      notEmpty(val) {
        if (val !== undefined && val !== null) {
          return true
        }
      },
      reset() {
        this.options.forEach(item => {
          this.$set(item, 'checked', false)
        })
      },
    },
    created() {
      this.set()
    },
    watch: {
      loading(loading) {
        this.disabled = loading
      },
      value(value) {
        this.set(value)
      },
      items(items) {
        this.dropdownItems = cloneDeep(items)
        this.set()
      },
      search(value) {
        this.autoComplete(value)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .b-dropdown {
    width: 100%;

    /deep/ {
      .dropdown-toggle {
        color: #757575;
        background: #fff;
        width: 100%;
        box-shadow: none;
        border-color: #e0e0e0;
        text-align: left;
        padding-right: 25px;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;

        &:after {
          right: 10px;
          top: calc(50% - 2px);
          position: absolute;
          margin: 0;
        }

        &[disabled] {
          background-color: #eeeeee;
          opacity: 1;
        }
      }

      .dropdown-menu {
        width: 100%;
        min-width: 300px;
        top: -5px !important;
        transform: unset !important;
        max-height: 300px;
        overflow-y: auto;
        transition: unset;

        &[x-placement='top-start'] {
          top: 0px !important;
        }

        .dropdown-item {
          text-overflow: ellipsis;
          white-space: pre;
          overflow-x: hidden;
          position: relative;

          > span {
            position: absolute;
            right: 5px;
            top: 6px;
          }

          &.checked:not(:focus) {
            background: #f1f4f5;
          }
        }
      }
    }
  }
</style>
