import qs from 'qs'

import forEach from 'lodash/forEach'
import merge from 'lodash/merge'
import intersection from 'lodash/intersection'
import get from 'lodash/get'
import set from 'lodash/set'

import cleanDeep from 'clean-deep'

export default {
  notEmpty(value) {
    return value !== null && value !== '' && typeof value !== 'undefined'
  },
  parseQuery(entity, path) {
    let match = path.match && path.match(/\?.*/),
      parsed = Object.assign({}, {})

    if (match && match[0]) {
      let query = qs.parse(match[0], { ignoreQueryPrefix: true, arrayFormat: 'brackets' })

      if (query.filters && query.filters[entity]) {
        parsed.filters = query.filters[entity]
      }

      if (query.sort && query.sort[entity]) {
        let querySort = query.sort[entity]
        parsed.sort = {
          by: querySort.match(/(?:-|)(\w*)/)[1],
          desc: querySort.slice(0, 1) === '-',
        }
      }

      if (query.page && query.page[entity] && query.page[entity] > 1) {
        parsed.pagination = {
          currentPage: parseInt(query.page[entity]),
        }
      }

      if (query.with && query.with[entity]) {
        parsed.with = query.with[entity].split(',')
      }
    }

    return parsed
  },
  parseResult(entity, result) {
    let parsed = {}

    if (result) {
      // Фильтры

      let filters = get(result, ['_filters', entity], result['_filters'])

      if (filters) {
        forEach(filters, (value, key) => {
          if (this.notEmpty(value)) {
            value = convertToString(value)
            value && set(parsed, 'filters.' + key, value)
          }
        })
      }
      // Пагинация
      if (result['_meta']) {
        let meta = result['_meta'],
          keys = ['currentPage', 'perPage', 'totalCount']
        if (intersection(Object.keys(meta), keys).length === keys.length) {
          keys.forEach(key => {
            set(parsed, 'pagination.' + key, meta[key])
          })
        }
      }
    }

    function convertToString(value) {
      if (typeof value === 'object') {
        forEach(value, (item, key) => {
          value[key] = convert(item)
        })

        return value
      } else {
        return convert(value)
      }

      function convert(value) {
        if (typeof value === 'number') value = value.toString()
        return value
      }
    }

    return parsed
  },
  parsePredefined(predefined) {
    let parsed = {}

    if (predefined) {
      // Фильтры
      if (predefined.filters) {
        forEach(predefined.filters, (value, key) => {
          if (this.notEmpty(value)) {
            set(parsed, 'filters.' + key, value)
          }
        })
      }
      // Связанные данные
      predefined.with && (parsed.with = predefined.with)
      // Extra
      predefined.extraQuery && (parsed.extraQuery = predefined.extraQuery)
    }

    return parsed
  },

  filtersQuery(filters) {
    return cleanDeep(filters)
  },

  sortQuery(sort) {
    return (sort && sort.by && (sort.desc ? '' : '-') + sort.by) || null
  },

  pageQuery(pagination) {
    return pagination && pagination.currentPage
  },

  withQuery(withs) {
    return (withs && withs.join()) || ''
  },

  structuredQuery(parsed) {
    let structured = {
      filters: this.filtersQuery(parsed.filters),
      sort: this.sortQuery(parsed.sort),
      page: this.pageQuery(parsed.pagination),
      with: this.withQuery(parsed.with),
    }

    return merge(structured, parsed.extraQuery)
  },

  query(name, structured) {
    let query = {}

    if (structured.filters && Object.keys(structured.filters).length) {
      Object.assign(query, {
        filters: {
          [name]: structured.filters,
        },
      })
    }

    if (structured.sort) {
      Object.assign(query, {
        sort: {
          [name]: structured.sort,
        },
      })
    }

    if (structured.page > 1) {
      Object.assign(query, {
        page: {
          [name]: structured.page,
        },
      })
    }

    if (structured.with.length) {
      Object.assign(query, {
        with: {
          [name]: structured.with,
        },
      })
    }

    return query
  },

  getQuery(entity, path, predefined) {
    let structured = this.structuredQuery(merge({}, this.parsePredefined(predefined), this.parseQuery(entity, path)))

    return this.query(entity, structured)
  },
}
