// Store
import store from '../store'
// API
import api from '../assets/js/api'

import reportsRoutes from './reports'
import moneyRoutes from './money'

import Dashboard from '../containers/Full'

export default [
  {
    path: '/login',
    name: 'Авторизация',
    component: () => import('../views/Login'),
    beforeEnter: function (to, from, next) {
      if (!store.state.auth.authorized) {
        if (!to.query.authFailed && (!to.query.logout || from.fullPath === '/')) {
          api.auth.login().then(
            user => {
              store.commit('setAuthorized', true)
              store.commit('setUser', user)

              next('/')
            },
            () => {
              next('/login?authFailed=true')
            }
          )
        } else {
          next()
        }
      } else {
        next('/')
      }
    },
  },
  {
    path: '/',
    redirect: '/dashboard',
    name: 'Главная',
    component: Dashboard,
    beforeEnter: function (to, from, next) {
      if (store.state.auth.authorized) {
        next()
      } else {
        api.auth.login().then(
          user => {
            store.commit('setAuthorized', true)
            store.commit('setUser', user)
            next()
          },
          () => {
            next('/login?authFailed=true')
          }
        )
      }
    },
    children: [
      {
        path: 'dashboard',
        name: 'Рабочий стол',
        component: () => import('../views/Dashboard/Dashboard'),
      },
      {
        path: 'projects',
        redirect: 'projects/list',
        name: 'Проекты',
        component: {
          template: '<router-view></router-view>',
        },
        children: [
          {
            path: 'list',
            name: 'Список проектов',
            component: () => import('../views/Projects/List'),
            meta: {
              entity: 'projects',
              predefined: {
                with: [
                  'client.person',
                  'price',
                  'leadType',
                  'tasks',
                  'project_start_date',
                  'project_end_date',
                  'worth',
                  'worth_percent',
                  'worth_average',
                  'cost',
                  'free_budget',
                  'free_budget_percent',
                  'margin',
                  'margin_percent',
                  'markup_percent',
                  'support_tariff',
                  'leadStatus',
                  'leadStatusColor',
                  'leadType',
                  'leadTypeId',
                  'employees',
                  'responsible',
                  'tuner',
                  'projectManager',
                  'followers',
                  'saleManager',
                  'tasks',
                  'tasksCount',
                  'client',
                  'payments',
                  'payments_total',
                  'actual_payments',
                  'actual_payments_total',
                  'future_payments',
                  'future_payments_total',
                  'employee_statistic',
                  'employee_statistic_total',
                  'project_status_log',
                  'average_hour_cost',
                  'remarks',
                  'discount_percent',
                  'payments_duty',
                  'cost_percent',
                  'reminder_hours',
                  'margin_payment',
                  'margin_per_hour',
                  'margin_payment_percent',
                  'days_in_work',
                  'days_in_work_percent',
                  'days_to_deadline',
                  'end_trial_operation',
                  'efficiency',
                  'days_in_production_plan',
                  'hours_mastering_percent',
                  'seconds_passed_last_touch',
                  'planHours',
                  'bonus_fund_plan',
                  'bonus_plan_percent',
                  'bonus_fund_fact',
                  'bonus_fact_percent',
                  'time_bonus',
                  'time_bonus_percent',
                  'date_bonus',
                  'date_bonus_percent',
                  'manager_bonus',
                  'manager_bonus_percent',
                  'tuner_bonus',
                  'tuner_bonus_percent',
                  'average_cost_plan_hours',
                  'average_cost_fact_hours',
                  'expenditureOperations',

                  'manager_bonus_total_percent',
                  'manager_bonus_total',
                  'manager_bonus_hours_plan',
                  'manager_bonus_hours_fact',
                  'manager_bonus_hours_overdue_percent',
                  'manager_bonus_hours_overdue_percent_calc',
                  'manager_bonus_date_penalty_percent',
                  'manager_bonus_date_penalty_coeff',
                  'manager_bonus_overdue_days',

                  'manager_bonus_hours_mastering_percent',
                  'manager_bonus_hours_saving_percent',
                  'manager_bonus_coeff',
                  'manager_bonus_gainful_percent',
                  'manager_bonus_date_max_penalty_percent',

                  'nps',

                  'direct_expenses',
                  'direct_expenses_total',
                  'actual_direct_expenses',
                  'actual_direct_expenses_total',
                  'future_direct_expenses',
                  'future_direct_expenses_total',

                  'margin_shipment',
                  'margin_shipment_percent',
                ],
                filters: {
                  preset: 'allInWork',
                },
              },
            },
          },
          {
            path: ':id',
            name: 'Проект',
            component: () => import('../views/Projects/Single'),
            meta: {
              entity: 'projects',
              predefined: {
                with: [
                  'price',
                  'responsible',
                  'followers',
                  'tasks',
                  'client.name',
                  'leadType',
                  'leadStatus',
                  'leadStatusColor',
                  'project_status_log',

                  'payments',
                  'payments_total',
                  'payments_duty',
                  'actual_payments',
                  'actual_payments_total',
                  'employee_statistic',
                  'employee_statistic_total',
                  'planHours',
                  'hours_mastering_percent',

                  'tuner',
                  'projectManager',
                  'saleManager',

                  'project_start_date',
                  'project_end_date',
                  'days_in_work',
                  'days_in_work_percent',
                  'days_to_deadline',
                ],
              },
            },
          },
        ],
      },
      {
        path: 'tasks',
        redirect: 'tasks/list',
        name: 'Задачи',
        component: {
          template: '<router-view></router-view>',
        },
        children: [
          {
            path: 'list',
            name: 'Список задач',
            component: () => import('../views/Tasks/List'),
            meta: {
              entity: 'tasks',
              predefined: {
                with: [
                  'status',
                  'type',
                  'parent',
                  'project',
                  'responsible',
                  'time',
                  'time_detail',
                  'is_bonus_task',
                  'bonus_task_member',
                  'worth',
                  'single_hours',
                  'average_hour_worth',
                  'employee_statistic',
                  'bonus_new_total',
                  'bonus_new_coeff',
                  'bonus_new_gainful_percent',
                  'bonus_new_hours_plan',
                  'bonus_new_hours_fact',
                  'bonus_new_hours_mastering_percent',
                  'bonus_new_hours_overdue_percent',
                  'bonus_new_hours_overdue_percent_calc',
                  'bonus_new_hours_saving_percent',
                  'bonus_new_overdue_days',
                  'bonus_new_date_penalty_percent',
                  'bonus_new_date_penalty_coeff',
                  'bonus_new_date_max_penalty_percent',
                  'bonus_new_employee_statistic',
                  'bonus_new_total_statistic',
                ],
              },
            },
          },
          {
            path: ':id',
            name: 'Задача',
            component: () => import('../views/Tasks/Single'),
            meta: {
              entity: 'tasks',
              predefined: {
                with: ['responsible', 'followers', 'tasks'],
              },
            },
          },
        ],
      },
      {
        path: 'employees',
        redirect: 'employees/list',
        name: 'Сотрудники',
        component: {
          template: '<router-view></router-view>',
        },
        children: [
          {
            path: 'list',
            name: 'Список сотрудников',
            component: () => import('../views/Employees/List'),
            meta: {
              entity: 'employees',
              path: 'employees/list',
              predefined: {
                with: ['department', 'user', 'person', 'position', 'role_id'],
              },
            },
          },
          {
            path: ':id',
            name: 'Сотрудник',
            component: () => import('../views/Employees/Single'),
            meta: {
              entity: 'employees',
              predefined: {
                with: ['user'],
              },
            },
          },
        ],
      },
      {
        path: 'clients',
        name: 'Клиенты',
        component: () => import('../views/Clients/List'),
        meta: {
          entity: 'clients',
          predefined: {
            with: ['responsible', 'responsible.person', 'person', 'person.fields'],
          },
        },
      },
    ]
      .concat(reportsRoutes)
      .concat(moneyRoutes),
  },
]
