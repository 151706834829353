<template>
  <a-locale-provider :locale="locale">
    <app-loader v-if="$root.loading" />
    <router-view v-else></router-view>
  </a-locale-provider>
</template>

<script>
  import ruRU from 'ant-design-vue/lib/locale-provider/ru_RU'
  import qs from 'qs'
  import debounce from 'lodash/debounce'
  import AppLoader from './components/AppLoader'

  export default {
    name: 'App',
    components: {
      AppLoader,
    },
    data() {
      return {
        locale: ruRU,
      }
    },
    computed: {
      routeUpdate() {
        return this.$store.state.currentView.routeUpdate
      },
      queries() {
        return this.$store.getters['entities/totalQuery']
      },
    },
    watch: {
      queries: debounce(function (queries) {
        if (this.routeUpdate) {
          this.$router.replace(this.$route.path + '?' + qs.stringify(queries, { arrayFormat: 'brackets' }))
          this.$store.commit('currentView/setRouteUpdate', false)
        }
      }, 300),
    },
  }
</script>

<style lang="scss">
  #app {
    height: 100%;
  }
</style>
