import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faCaretLeft,
  faCaretRight,
  faDollarSign,
  faEdit,
  faPencilAlt,
  faPlusSquare,
  faTimes,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
import get from 'lodash/get'
import set from 'lodash/set'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import numeral from 'numeral'
import uniqid from 'uniqid'
import Vue from 'vue'
import App from './App'
import Antd from 'ant-design-vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css'
import './main.scss'

Vue.use(Antd)
Vue.use(BootstrapVue)

library.add(
  faEdit,
  faPlusSquare,
  faDollarSign,
  faUser,
  faPencilAlt,
  faCaretLeft,
  faCaretRight,
  faTimes,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faAngleRight
)

Vue.component('fa', FontAwesomeIcon)

numeral.localeData().delimiters.thousands = ' '

Vue.filter('formatNumber', (value, format) => {
  format = format || '0,0'
  return numeral(value).format(format)
})

Vue.filter('formatNumberNORMAL', function (number, decimals, dec_point, thousands_sep) {
  number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
  let n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 2 : Math.abs(decimals),
    sep = typeof thousands_sep === 'undefined' ? ' ' : thousands_sep,
    dec = typeof dec_point === 'undefined' ? '.' : dec_point,
    s = '',
    toFixedFix = function (n, prec) {
      let k = Math.pow(10, prec)
      return '' + Math.round(n * k) / k
    }
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
  }
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
})

Vue.filter('ending', function (count, e1, e2, e3) {
  count = parseInt(count)
  let n = Math.abs(count) % 100
  let n1 = n % 10
  if (n > 10 && n < 20) return e3
  if (n1 > 1 && n1 < 5) return e2
  if (n1 === 1) return e1

  return e3
})

momentDurationFormatSetup(moment)
moment.locale('ru')

Vue.prototype.$moment = moment

Vue.filter('formatDate', function (date, format) {
  format = format || 'DD.MM.YYYY'
  return moment(date).format(format)
})

Vue.filter('formatDuration', (input, format = 'h[ч] mm[м]', trim = 'both', type = 'seconds') => {
  return moment.duration(input, type).format(format, {
    trim,
  })
})

Vue.filter('formatDateRelative', input => {
  let date = moment(input)

  if (moment().isSame(date, 'day')) {
    // Сегодня
    return date.fromNow()
  } else if (moment().subtract(1, 'day').isSame(date, 'day')) {
    // Вчера
    return date.calendar()
  } else {
    // Дальше
    return date.fromNow()
  }
})

Vue.filter('slicer', (string, length = 30) => {
  if (string.length > length) {
    return string.slice(0, length - 1) + '…'
  } else return string
})

Vue.prototype.$store = store

Vue.prototype.$uniqid = uniqid
Vue.mixin({
  methods: {
    getUniqId() {
      return uniqid()
    },
  },
})

Vue.prototype.$axios = axios

Vue.prototype.$dotGet = get
Vue.prototype.$dotSet = set

const app = new Vue({
  el: '#app',
  render: h => h(App),
  data: { loading: true },
  router,
  store,
  components: {
    FontAwesomeIcon,
  },
})

router.beforeEach(async (to, from, next) => {
  await store.dispatch('loader', { start: true })
  next()
})

router.afterEach(() => {
  setTimeout(async () => {
    await store.dispatch('loader', { start: false })
  }, 5000)

  setTimeout(() => {
    app.loading = false
  }, 500)
})
