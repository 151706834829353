<template>
  <div id="prepare-loader" class="prepare-loader">
    <div class="prepare-loader__spinner">
      <img src="../assets/img/loader.png" />
    </div>
    <div class="prepare-loader__logo" v-html="require('!!svg-inline-loader!../assets/img/loader-logo-color.svg')"></div>
  </div>
</template>

<script>
  export default {
    name: 'Loader',
  }
</script>

<style lang="scss">
  .prepare-loader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, #f9f9f9 10%, #eeeff3 100%);

    &__logo {
      position: fixed;
      bottom: 55px;
      left: 0;
      right: 0;
      text-align: center;

      svg {
        width: 175px;
      }
    }

    &__spinner {
      display: block;
      position: fixed;
      top: 45%;
      left: 50%;

      img {
        height: 52px;
        width: 52px;
        animation: nprogress-spinner 900ms linear infinite;
        margin-top: -26px;
        margin-left: -26px;
      }
    }
  }
</style>
